<template>
  <div>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              :hint="isSearchAdded ? searchHint : ''"
              persistent-hint
              append-icon="mdi-magnify"
              outlined
              dense
              @keyup.enter="getRecordsFromServer({itemsPerPage: 10, pageStart: 0})"
              @keydown.esc="getAllRecordsFromServer({ itemsPerPage: 10, pageStart: 0 })"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <p
        v-if="!isSearchAdded"
        class="info-text"
      >
        <v-icon>mdi-account-hard-hat-outline</v-icon> Indicates Project Work <span class="division-style">|</span>
        <v-icon>mdi-account-wrench-outline</v-icon>Indicates Project Issue
      </p>
      <v-data-table
        :headers="userActivities.headers"
        :items="appUserAllActivities"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="id"
        disable-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 30]
        }"
        :loading="updateProgress"
        :server-items-length="totalRecords"
        class="elevation-1"
        mobile-breakpoint="100"
        light
        @pagination="getRecordsFromServer"
      >
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr>
            <td class="type-style">
              {{ item.type }}<span v-if="item.type === 'worker'">
                <v-icon
                  v-if="item.work_details.type === 'project-work'"
                  small
                >mdi-account-hard-hat-outline</v-icon>
                <v-icon
                  v-if="item.work_details.type === 'project-issue'"
                  small
                >mdi-account-wrench-outline</v-icon>
              </span>
            </td>
            <td>{{ item.project.name }}</td>
            <td>
              <font v-if="item.type === 'contractor'">
                {{ item.contract_details.contract.name }}
              </font>
              <font v-else-if="item.type === 'worker'">
                <font v-if="item.work_details.type === 'project-work'">
                  {{ item.work_details.budget.name }}
                </font>
                <font v-else>
                  {{ item.work_details.issue.title }}
                </font>
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>{{ item.checkin_time | convertToLocal }}</td>
            <td>{{ item.checkin_gate.name }}</td>
            <td>
              <font
                v-if="item.checkout_time === null"
                class="My-checkout-time"
              >
                Active*
              </font>
              <font v-else>
                {{ item.checkout_time | convertToLocal }}
              </font>
            </td>
            <td>
              <v-btn
                icon
                small
                @click.stop
                @click="expand(!isExpanded), typeSelected(item)"
              >
                <v-icon
                  v-if="!isExpanded"
                >
                  mdi-arrow-down-drop-circle
                </v-icon>
                <v-icon v-if="isExpanded">
                  mdi-arrow-up-drop-circle
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template #expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
          >
            <v-row>
              <v-col
                v-if="item.type === 'worker'
                  &&
                  item.work_details.type === 'project-work'"
                cols="12"
                lg="6"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title class="status-font-style">
                      Check-In Details
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-content class="ml-1">
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Type: Project Work
                            </strong>
                          </v-list-item-title>
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Budget Name: {{ item.work_details.budget.name }}
                            </strong>
                          </v-list-item-title>
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Description: <font v-if="item.work_details.budget.description !== null">
                                {{ item.work_details.budget.description }}
                              </font>
                              <font v-else>N/A</font>
                            </strong>
                          </v-list-item-title>
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              High Risk Work: <font
                                :color="getHrwColor(item.work_details.budget.high_risk_work)"
                                class="my-hrw-style"
                              >{{ item.work_details.budget.high_risk_work | displayHighRisk }}</font>
                            </strong>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                v-if="item.type === 'worker'
                  &&
                  item.work_details.type === 'project-issue'"
                cols="12"
                lg="6"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title class="status-font-style">
                      Check-In Details
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-content class="ml-1">
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Type: Project Issue
                            </strong>
                          </v-list-item-title>
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Issue Title: {{ item.work_details.issue.title }}
                            </strong>
                          </v-list-item-title>
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Description: <font v-if="item.work_details.issue.description !== null">
                                {{ item.work_details.issue.description }}
                              </font>
                              <font v-else> N/A</font>
                            </strong>
                          </v-list-item-title>
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              High Risk Work: <font
                                :color="getHrwColor(item.work_details.issue.high_risk_work)"
                                class="my-hrw-style"
                              >{{ item.work_details.issue.high_risk_work | displayHighRisk }}</font>
                            </strong>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                v-if="item.type === 'visitor'"
                cols="12"
                lg="6"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title class="status-font-style">
                      Check-In Details
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-content class="ml-1">
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Visit Purpose: {{ item.visit_details.purpose }}
                            </strong>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                v-if="item.type === 'worker'
                  && item.work_details.type === 'project-issue'"
                cols="12"
                lg="6"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title class="status-font-style">
                      Check-Out Details
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-content class="ml-1">
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Status: <font
                                class="status-font-style"
                                :color="getStatusColor(item.work_details.status)"
                              >{{ item.work_details.status }}</font></strong>
                          </v-list-item-title>
                          <v-list-item-title class="my-style">
                            <strong
                              v-if="item.work_details.status === 'pending'"
                              class="status-font-style"
                            >
                              Pending Reason: <font> {{ item.work_details.pending_reason }}</font>
                            </strong>
                            <strong
                              v-if="item.work_details.status === 'completed'"
                              class="status-font-style"
                            >
                              Root Cause: <font> {{ item.work_details.root_cause }}</font>
                            </strong>
                          </v-list-item-title>
                          <br>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                v-if="item.type === 'visitor'"
                cols="12"
                lg="6"
              >
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title class="status-font-style">
                      Check-Out Details
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-content class="ml-1">
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Status: <font
                                class="status-font-style"
                                :color="getStatusColor(item.visit_details.status)"
                              >{{ item.visit_details.status }}</font></strong>
                          </v-list-item-title>
                          <v-list-item-title class="my-style">
                            <strong class="status-font-style">
                              Pending Reason: <font
                                v-if="item.visit_details.pending_reason !== null"
                                class="status-font-style"
                              > {{ item.visit_details.pending_reason }}</font>
                              <font
                                v-else
                                class="status-font-style"
                              > N/A </font>
                            </strong>
                          </v-list-item-title>
                          <br>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import Constants from 'src/constants';
  import ModuleHelper from 'src/helpers/module-helper';

  export default {
    name: 'AppUserAllActivities',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      displayHighRisk (status) {
        if (status === true) {
          return 'Yes*';
        } else {
          return 'No';
        }
      },
    },
    data () {
      return {
        search: null,
        loading: false,
        userActivities: {
          headers: [
            { text: 'Type', align: 'start', value: 'type' },
            { text: 'Project', value: 'project.name' },
            { text: 'Check-In Item', value: 'check-In item' },
            { text: 'Check-In At', value: 'checkin_time' },
            { text: 'Check-In Gate', value: 'checkin_gate.name' },
            { text: 'Check-Out At', value: 'checkout_time' },
            { text: 'More', value: 'more' },
          ],
        },
        expanded: [],
        singleExpand: true,
        helper: new ModuleHelper(),
        updateProgress: false,
      };
    },
    computed: {
      paginatedRecordsStructure () {
        return this.$store.getters['appusers/getAppUserAllActivities'];
      },
      appUserAllActivities () {
        const records = this.paginatedRecordsStructure;
        return records.data;
      },
      totalRecords () {
        if (Object.keys(this.paginatedRecordsStructure).length > 0) {
          return this.paginatedRecordsStructure.total;
        }
        return 0;
      },
      isSearchAdded () {
        if (this.search === null || this.search === '') {
          return false;
        }
        return true;
      },
      searchHint () {
        return `Press Enter to filter by project name: '${this.search}' or press ESC to clear.`;
      },
    },
    watch: {
      isSearchAdded (val) {
        if (val === false) {
          this.getAllRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
        }
      },
    },
    async mounted () {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    methods: {
      async getAllRecordsFromServer ({ itemsPerPage, pageStart }) {
        this.updateProgress = true;
        this.search = null;
        await this.$store.dispatch('appusers/fetchAppUserAllActivities', {
          appUserId: this.$route.params.externalId,
          params: {
            params: {
              limit: itemsPerPage,
              startPage: pageStart,
            },
          },
        }).catch(() => {
          this.updateProgress = false;
        });
        this.updateProgress = false;
      },
      async getRecordsFromServer ({ itemsPerPage, pageStart }) {
        this.updateProgress = true;
        await this.$store.dispatch('appusers/fetchAppUserAllActivities', {
          appUserId: this.$route.params.externalId,
          params: {
            params: {
              projectName: this.search === '' ? null : this.search,
              limit: itemsPerPage,
              startPage: pageStart,
            },
          },
        }).catch(() => {
          this.updateProgress = false;
        });
        this.updateProgress = false;
      },
      getStatusColor (status) {
        if (status === 'completed') {
          return '#2E7D32';
        } else {
          return '#C62828';
        }
      },
      typeSelected (item) {
        if (item.type === 'staff' || item.type === 'contractor') {
          this.$store.dispatch('alert/onAlert', {
            message: 'No details to display.',
            type: Constants.ALERT_TYPE_INFO,
         });
        }
      },
      getHrwColor (value) {
        if (value === true) return '#C62828';
        else { return '#2E7D32'; }
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.type-style {
  text-transform: capitalize;
  font-weight: bold;
}
.my-style {
  margin-bottom: 10px !important;
}
.my-hrw-style {
  text-transform: capitalize;
  font-weight: bold;
}
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
.status-font-style {
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  font-weight: bold;
}
.job-status-style {
  text-transform: capitalize;
  font-weight: bold;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  font-size: 14px;
  margin-left: 15px;
  margin-top: -20px;
  padding-bottom: 10px;
}
.division-style {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
