<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getAllDocumentsUploaded()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-file-document-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Documents
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getAppUserActivities()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-transit-transfer
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Activities
            </v-list-item-title>
            <span
              v-if="(subscriptionCheck('trial'))"
              class="premium-container"
            >
              <v-icon class="premium-style">
                mdi-crown-circle
              </v-icon>
            </span>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-btn
      color="red darken-3"
      class="ml-7 my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left-bold
      </v-icon>
      Back
    </v-btn>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  style="font-size:25px;color:#37474F;font-weight:bold;"
                >
                  mdi-account-check
                </v-icon>
              </v-list-item-icon>
              Details
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getAllDocumentsUploaded()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-file-document-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Documents
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getAppUserActivities()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-transit-transfer
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Activities
            </v-list-item-title>
            <span
              v-if="(subscriptionCheck('trial'))"
              class="premium-container"
            >
              <v-icon class="premium-style">
                mdi-crown-circle
              </v-icon>
            </span>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <app-user-documents v-if="showDocumentsUploaded" />
    <app-user-activities v-if="showActivities" />
  </v-container>
</template>

<script>
  import AppUserDocuments from 'src/views/dashboard/appusers/AppUserDocuments';
  import AppUserActivities from 'src/views/dashboard/appusers/AppUserActivities.vue';
  import ModuleHelper from 'src/helpers/module-helper';
  import global from 'src/mixins/global';

  export default {
    name: 'AppUserLandingPage',
    components: {
      'app-user-activities': AppUserActivities,
      'app-user-documents': AppUserDocuments,
    },
    mixins: [global],
    data: () => ({
      showDocumentsUploaded: true,
      selectedItem: -1,
      showActivities: false,
      helper: new ModuleHelper(),
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
    },
    mounted () {
      this.selectedItem = 0;
    },
    methods: {
      back () {
        this.$router.push({ name: 'App Users' });
      },
      getAllDocumentsUploaded () {
        this.showActivities = false;
        this.showDocumentsUploaded = true;
        },
      getAppUserActivities () {
        this.showDocumentsUploaded = false;
        this.showActivities = true;
      },
    },
  };
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.my-back-btn-style {
  margin-bottom: -20px;
}
@media (max-width: 960px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
}
.premium-style {
  font-size:25px;
  color:#006883!important;
  font-weight:bold;
}
.premium-container{
  background-color: #ffffff!important;
  border-radius: 50%;
}
.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
