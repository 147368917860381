<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card
      class="my-card-style"
    >
      <v-card
        dark
        flat
      >
        <v-img
          :height="400"
          cover
          :src="image"
          gradient="to top, rgba(0,0,0,.44), rgba(0,0,0,.44)"
        >
          <v-container class="fill-height">
            <v-row
              align="center"
              justify="center"
            >
              <div class="my-date-style">
                {{ currentDateTime }}
              </div>
            </v-row>
          </v-container>
        </v-img>
      </v-card>
    </v-card>
    <v-card-text class="py-0">
      <v-timeline
        align-top
        dense
      >
        <v-timeline-item
          v-for="(item, index) in latestTenAppUserActivities"
          :key="index"
          color="#FF3700"
          small
        >
          <v-row class="pt-1">
            <v-col cols="3">
              <p
                class="my-p-style"
              >
                <font
                  class="font-my-style"
                >
                  {{ item.checkin_time | convertStringToLocalDatetime }}
                </font>
              </p>
            </v-col>
            <v-col>
              <strong>Activity {{ index + 1 }}</strong>
              <br>
              <br>
              <div>
                <p
                  class="my-p-style"
                >
                  Check-In Gate:-
                  <font
                    class="font-my-style"
                  >
                    {{ item.checkin_gate.name }}
                  </font>
                </p>
              </div>
              <div>
                <p
                  class="my-p-style"
                >
                  Checked-In Time:-
                  <font
                    class="font-my-style"
                  >
                    {{ item.checkin_time | convertStringToLocalDatetime }}
                  </font>
                </p>
              </div>
              <div>
                <p
                  class="my-p-style"
                >
                  Type:-
                  <font
                    class="type-style"
                  >
                    {{ item.type }}
                  </font>
                </p>
              </div>
              <div>
                <p
                  v-if="item.checkout_gate !== null"
                  class="my-p-style"
                >
                  Checked-Out Gate:-
                  <font
                    class="font-my-style"
                  >
                    {{ item.checkout_gate.name }}
                  </font>
                </p>
                <p v-else>
                  <font
                    class="font-my-style"
                  >
                    N/A
                  </font>
                </p>
              </div>
              <div>
                <p
                  v-if="item.checkout_time !== null"
                  class="my-p-style"
                >
                  Checked-Out Time:-
                  <font
                    class="font-my-style"
                  >
                    {{ item.checkout_time | convertStringToLocalDatetime }}
                  </font>
                </p>
                <p
                  v-else
                  class="my-p-style"
                >
                  Checked-Out Time:-
                  <font
                    class="not-checked-out-style"
                  >
                    Active*
                  </font>
                </p>
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>

<script>
import moment from 'moment';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import { cdnUrl } from 'src/app-globals';

  export default {
    name: 'AppUserRecentActivities',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
    convertStringToLocalDatetime (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
    },
    data: () => ({
      image: `${cdnUrl}/website/Construction-Site.webp`,
      tab: null,
      currentDateTime: null,
      loading: false,
    }),
    computed: {
      paginatedRecordsStructure () {
        return this.$store.getters['appusers/getAppUserRecentActivities'];
      },
      latestTenAppUserActivities () {
        const records = this.paginatedRecordsStructure;
        return records.data;
      },
      totalRecords () {
        if (Object.keys(this.paginatedRecordsStructure).length > 0) {
          return this.paginatedRecordsStructure.total;
        }
        return 0;
      },
    },
    async mounted () {
      await this.fetchRecentAppUserActivities();
    },
    created () {
      this.currentDateTime = moment().format('MMMM Do YYYY, h:mm:ss A');
      setInterval(() => this.liveDateTime());
      },
    methods: {
      async fetchRecentAppUserActivities () {
        this.loading = true;
        await this.$store.dispatch('appusers/fetchAppUserRecentActivities', {
          params: {
            limit: 10,
            startPage: 0,
          },
          appUserId: this.$route.params.externalId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
        },
      liveDateTime () {
        this.currentDateTime = moment().format('MMMM Do YYYY, h:mm:ss A');
      },
    },
  };
</script>
<style scoped>
.my-date-style {
  font-size: 25px;
  font-weight: bold;
}
.font-my-style {
  font-weight: bold;
  font-size: 15px;
  color: #37474F;
  text-transform: capitalize;
}
.my-p-style {
    font-size: 16px;
    font-weight: bold;
    color: #546E7A;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.not-checked-out-style {
  font-weight: bold;
  font-size: 15px;
  color:#2E7D32;
}
.type-style {
  font-weight: bold;
  font-size: 15px;
  color: #FF3700;
  text-transform: capitalize;
}
</style>
